<template>
  <div>
    <div class="flex items-center justify-between">
      <tab-headers
        >Manage Plan
        <template #text>
          Create, edit, delete and following up on listing and subscription
          plans here
        </template>
      </tab-headers>
      <div>
        <a-button type="primary" @click="showDrawer"> Create Plan </a-button>
      </div>
    </div>
    <div class="bg-white py-2 rounded shadow">
      <a-table
        :loading="gettingSubscription"
        :columns="headers"
        :data-source="subscriptions"
        rowKey="record._id"
      >
        <span slot="duration" slot-scope="record">
          {{ record.duration }} {{ record.durationPeriod }}
        </span>
        <span slot="trialPeriod" slot-scope="record">
          {{ record.trialPeriod }} {{ record.trialPeriodDuration }}
        </span>
        <span slot="action" slot-scope="record">
          <div class="flex">
            <a-button
              type="primary"
              class="mr-2"
              @click="editSubscription(record)"
              >Edit
            </a-button>
            <a-button type="danger" @click="showDeleteConfirm(record._id)">
              Delete
            </a-button>
          </div>
        </span>
      </a-table>
    </div>
    <a-drawer
      title="Create a new subscription"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form-model
        ref="subscriptionForm"
        :rules="rules"
        layout="vertical"
        :model="form"
      >
        <a-form-model-item label="Title" prop="title">
          <a-input
            v-model="form.title"
            type="text"
            :disabled="creating"
            placeholder="Title"
          />
        </a-form-model-item>
        <a-form-model-item label="Description" prop="description">
          <a-textarea
            v-model="form.description"
            type="text"
            :disabled="creating"
            auto-size
            placeholder="Description"
          >
          </a-textarea>
        </a-form-model-item>
        <a-form-model-item label="Cost" prop="cost">
          <a-input
            v-model="form.cost"
            type="number"
            :disabled="creating"
            placeholder="Cost"
          />
        </a-form-model-item>
        <a-form-model-item label="Duration" prop="duration">
          <a-input
            v-model="form.duration"
            type="number"
            :disabled="creating"
            placeholder="Duration"
          />
        </a-form-model-item>

        <a-form-model-item label="Duration Period" prop="durationPeriod">
          <a-select v-model="form.durationPeriod" default-value="days">
            <a-select-option value="days"> Days </a-select-option>
            <a-select-option value="weeks"> Weeks </a-select-option>
            <a-select-option value="months"> Months </a-select-option>
            <a-select-option value="years"> Years </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Plan Type" prop="type">
          <a-select v-model="form.type" default-value="subscription">
            <a-select-option value="subscription">
              Subscription
            </a-select-option>
            <a-select-option value="listing"> Listing </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="form.type === 'listing'"
          label="Listing Category"
          prop="listingCategory"
        >
          <a-select v-model="form.listingCategory" default-value="global">
            <a-select-option value="global"> Global </a-select-option>
            <a-select-option value="local"> Local </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="Trial"
          prop="trialPeriod"
          extra="Leave at 0 if plan has not trial Period"
        >
          <a-input
            v-model="form.trialPeriod"
            type="number"
            :disabled="creating"
            placeholder="Trial Period"
          />
        </a-form-model-item>
        <a-form-model-item
          label="Trial Duration Period"
          prop="trialPeriodDuration"
        >
          <a-select v-model="form.trialPeriodDuration" default-value="days">
            <a-select-option value="days"> Days </a-select-option>
            <a-select-option value="weeks"> Weeks </a-select-option>
            <a-select-option value="months"> Months </a-select-option>
            <a-select-option value="years"> Years </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          Cancel
        </a-button>
        <a-button :loading="creating" type="primary" @click="onSubmit">
          {{ isEditing ? 'Update' : 'Create' }}
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import TabHeaders from '../../components/typography/TabHeaders.vue'
import subscriptionApi from '../../api/subscription'

const headers = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
  },
  {
    title: 'Duration',
    key: 'duration',
    scopedSlots: { customRender: 'duration' },
  },
  {
    title: 'Subscribed Users',
    key: 'usageCount',
    dataIndex: 'usageCount',
  },
  {
    title: 'Plan Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Plan Category',
    key: 'listingCategory',
    dataIndex: 'listingCategory',
  },
  {
    title: 'Trial Period',
    key: 'trialPeriod',
    scopedSlots: { customRender: 'trialPeriod' },
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'Subscription',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      visible: false,
      gettingSubscription: false,
      rules: {
        title: {
          required: true,
          message: 'Enter a title',
        },
        cost: [{ required: true, message: 'Enter a valid cost' }],
        duration: { required: true, message: 'Enter a valid duration' },
        trialPeriod: { required: true, message: 'Enter a valid duration' },
        type: { required: true, message: 'Please select a type' },
      },
      creating: false,
      headers,
      subscriptions: [],
      selectedPlan: {},
      isEditing: false,
      form: {
        title: '',
        cost: '',
        duration: '',
        trialPeriod: '',
        durationPeriod: 'days',
        trialPeriodDuration: 'days',
        type: 'subscription',
        listingCategory: 'global',
      },
    }
  },
  created() {
    this.getSubscription()
  },
  methods: {
    editSubscription(data) {
      this.isEditing = true
      this.form = data
      this.visible = true
    },
    async getSubscription() {
      this.gettingSubscription = true
      const req = await subscriptionApi(this.axios).getPlans()
      this.subscriptions = req.data.data
      this.gettingSubscription = false
    },
    async editingSubscription() {
      this.creating = true

      delete this.form.createdAt
      delete this.form.updatedAt
      delete this.form.__v
      delete this.form.usageCount

      const req = await subscriptionApi(this.axios).updatePlan({
        planId: this.form._id,
        data: this.form,
      })
      if (req.error) {
        this.$store.dispatch('notification/updateNotification', {
          type: 'error',
          message: req.message,
        })
        this.creating = false
        return
      }
      this.$store.dispatch('notification/updateNotification', {
        type: 'success',
        title: 'Successful',
        message: req.message,
      })
      this.form = {
        title: '',
        cost: '',
        duration: '',
        durationPeriod: 'days',
        type: 'subscription',
        listingCategory: 'global',
      }
      this.getSubscription()
      this.creating = false
      this.isEditing = false
      this.onClose()
    },
    async createSubscription() {
      this.creating = true
      const data = {
        title: this.form.title,
        cost: this.form.cost,
        duration: this.form.duration,
        durationPeriod: this.form.durationPeriod,
        type: this.form.type,
        description: this.form.description,
        trialPeriod: this.form.trialPeriod,
        trialPeriodDuration: this.form.trialPeriodDuration,
      }

      if (this.form.type === 'listing') {
        data.listingCategory = this.form.listingCategory
      }

      const req = await subscriptionApi(this.axios).create(data)

      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.message,
        })
        this.$store.dispatch('notification/updateNotification', {
          type: 'error',
          message: req.message,
        })
        this.creating = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
      this.getSubscription()
      this.creating = false
      this.onClose()
      this.form = {
        title: '',
        cost: '',
        duration: '',
        durationPeriod: 'days',
        type: 'subscription',
        listingCategory: 'global',
      }
    },
    onSubmit() {
      this.$refs.subscriptionForm.validate((valid) => {
        if (valid) {
          if (this.isEditing) {
            this.editingSubscription()
          } else {
            this.createSubscription()
          }
        } else {
          return false
        }
      })
    },
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.form = {
        title: '',
        cost: '',
        duration: '',
        durationPeriod: 'days',
        type: 'subscription',
        listingCategory: 'global',
      }
    },
    async deletePlan(id) {
      this.creating = true
      const req = await subscriptionApi(this.axios).deletePlan({ planId: id })
      if (req.error) {
        this.$notification.error({
          message: 'An error occurred',
          description: req.message,
        })
        this.creating = false
        return
      }
      this.$notification.success({
        message: 'Delete Successful',
        description: req.message,
      })
      this.creating = false
      this.getSubscription()
    },
    showDeleteConfirm(id) {
      this.$confirm({
        title: 'Are you sure about deleting this Plan?',
        content:
          'Please confirm you want to delete this plan, Note that users on this plan will be unsubscribed.',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.deletePlan(id)
        },
        onCancel() {},
      })
    },
  },
}
</script>
